export const imageUrls = {
  visa: "https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png",
  mastercard:
    "https://brand.mastercard.com/content/dam/mccom/brandcenter/thumbnails/mastercard_vrt_rev_92px_2x.png",
  amex: "https://prodimagesbucket.blob.core.windows.net/card-logos/amex.png",
  discover:
    "https://www.discover.com/company/images/newsroom/media-downloads/discover.png",
  diners:
    "https://cdn4.iconfinder.com/data/icons/simple-peyment-methods/512/diners_club-512.png",
  jcb: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/1280px-JCB_logo.svg.png",
};

export function getCardType(number) {
  const re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (let key in re) {
    if (re[key].test(number)) {
      return { imageUrl: imageUrls[key], type: key };
    }
  }
}

export function cardNumberFormat(value) {
  let v = value?.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  let matches = v?.match(/\d{4,16}/g);
  let match = (matches && matches[0]) || "";
  let parts = [];
  for (let i = 0; i < match?.length; i += 4) {
    parts.push(match?.substring(i, i + 4));
  }

  if (parts?.length) {
    return parts?.join(" ");
  } else {
    return value;
  }
}

export function cardNumberCVVFormat(value) {
  return value
    ?.replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    ?.match(/\d{3,4}/g);
}
