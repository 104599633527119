import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CreditCard } from "@mui/icons-material";
import { getCardType } from "../../utils/CardType";

function CustomPaymentCard({
  addCardNumber,
  setAddCardNumber,
  addCardCVV,
  setAddCardCVV,
  addZip,
  setAddZip,
  addCardExpiry,
  setAddCardExpiry,
  error,
  setError,
  addCardHolderName,
  setAddCardHolderName,
  bookingFinalLoading,
}) {
  const [addCardType, setAddCardType] = useState();

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber]);

  const checkExpiry = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Month starts from 0, so add 1

    if (addCardExpiry.length !== 4) {
      return false;
    }
    const enteredMonth = Number(addCardExpiry.slice(0, 2));
    const enteredYear = Number(addCardExpiry.slice(2, 4));
    const minExpiryMonth =
      currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const minExpiryYear = currentYear % 100; // Get last two digits of the current year
    if (enteredMonth < 1 || enteredMonth > 12) {
      return false;
    }
    if (
      enteredYear < minExpiryYear ||
      (enteredYear === minExpiryYear && enteredMonth < currentMonth)
    ) {
      return false;
    }
    return true;
  };

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      // setError({ ...error, number: false });
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    // setError({ ...error, number: !flag });
    return flag;
  };

  useEffect(() => {
    setError({
      number: false,
      cvv: false,
      expiry: false,
      zip: false,
      cardHolderName: false,
    });
  }, [addCardNumber, addCardExpiry, addCardCVV, addZip, addCardHolderName]);

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Payment information
      </Typography>
      <Box>
        <TextField
          required
          fullWidth
          size="small"
          id="outlined-name"
          label={"Card number"}
          name="cardNumber"
          type={"text"}
          value={addCardNumber}
          onChange={(e) => {
            e.target.value.length <= 16 &&
              (/^[0-9]*$/.test(e.target.value)
                ? setAddCardNumber(e.target.value)
                : setAddCardNumber(addCardNumber));
            setError({ ...error, number: !checkLuhn() });
          }}
          error={error.number || !checkLuhn() ? true : false}
          helperText={
            error.number || !checkLuhn() ? `${"invalid card number"}` : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getCardType(addCardNumber?.slice(0, 16)) && (
                  <img
                    style={{
                      width: "60px",
                      height: "40px",
                      objectFit: "contain",
                    }}
                    src={getCardType(addCardNumber?.slice(0, 16))?.imageUrl}
                    alt="card"
                  />
                )}
              </InputAdornment>
            ),
          }}
          disabled={bookingFinalLoading}
        />
      </Box>
      <Box className="basic-detail">
        <Box className="basicItem">
          <TextField
            required
            fullWidth
            size="small"
            id="outlined-name"
            label={"Card CVV"}
            name="cardCVV"
            type={"text"}
            value={addCardCVV}
            onChange={(e) =>
              e.target.value.length <= 4 &&
              (/^[0-9]*$/.test(e.target.value)
                ? setAddCardCVV(e.target.value)
                : setAddCardCVV(addCardCVV))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreditCard />
                </InputAdornment>
              ),
            }}
            error={error.cvv}
            helperText={error.cvv ? "CVV is required" : ""}
            disabled={bookingFinalLoading}
          />
        </Box>
        <Box className="basicItem">
          <TextField
            required
            fullWidth
            size="small"
            id="outlined-name"
            label={"Card expiry (MMYY)"}
            name="cardExpiry"
            type="text"
            value={addCardExpiry}
            onChange={(e) => {
              const value = e.target.value;

              // Only allow numbers and a length of 4
              if (/^[0-9]*$/.test(value) && value.length <= 4) {
                setAddCardExpiry(value);
                setError({ ...error, expiry: !checkExpiry() });
              }
            }}
            error={(!checkExpiry() && addCardExpiry.length > 0) || error.expiry}
            helperText={
              (!checkExpiry() && addCardExpiry.length > 0) || error.expiry
                ? "Invalid expiry date"
                : ""
            }
            disabled={bookingFinalLoading}
          />
        </Box>
        <Box className="basicItem">
          <TextField
            required
            fullWidth
            size="small"
            id="outlined-name"
            label={"Name on card"}
            name="Name on card"
            type={"text"}
            value={addCardHolderName}
            onChange={(e) => setAddCardHolderName(e?.target.value)}
            error={error?.cardHolderName}
            helperText={
              error?.cardHolderName ? "Card holder name is required" : ""
            }
            disabled={bookingFinalLoading}
          />
        </Box>
        <Box className="basicItem">
          <TextField
            required
            fullWidth
            size="small"
            id="outlined-name"
            label={"Billing zipcode"}
            name="zipcode"
            type={"text"}
            value={addZip}
            onChange={(e) =>
              e.target.value.length <= 10 &&
              (/^[a-zA-Z0-9]*$/.test(e.target.value)
                ? setAddZip(e.target.value)
                : setAddZip(addCardCVV))
            }
            error={error.zip}
            helperText={error.zip ? "Zip code is required" : ""}
            disabled={bookingFinalLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CustomPaymentCard;
