import React, { useEffect } from "react";
import "../css/Home.scss";
import { Backdrop, Box } from "@mui/material";

import DateSelectionPage from "./DateSelectionPage";
import { useState } from "react";
import ProperyCategory from "./ProperyCategory";
import PropertyRates from "./PropertyRates";
import SummaryPage from "./SummaryPage";
import DetailsPage from "./DetailsPage";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  ENV,
  baseUrl,
  baseUrlDjango,
  razorPayKey,
  selfServiceUrl,
} from "../utils/constants";
import {
  saveCurrency,
  saveParams,
  savePropertyInfo,
} from "../redux/slice/slice";
import PaginationComponent from "../common/PaginationComponent";
import PaginationDesktop from "../common/PaginationDesktop";
import {
  STRIPE_DIRECT_CHARGE_TYPE,
  STRIPE_SEPARATE_CHARGE_TYPE,
  getCurrencySymbol,
} from "../common/common";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MD5 from "crypto-js/md5";
import Checkout from "./stripe/Checkout";
import { sendSlackNotification } from "../utils/utils";
import { handleAmplitude } from "../amplitude/AmplitudeUtils";

export default function Home() {
  const Currency = useSelector((state) => state.Currency);
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const dispatch = useDispatch();
  const [allParams, setAllParams] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const hotelId = urlParams.get("hotel_id");
  // const hotel_id = urlParams.get("hotel_id");
  const navigate = useNavigate();
  useEffect(() => {
    if (!hotelId) {
      navigate("/access-denied");
    }
  }, [hotelId]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { checkin, num_nights, num_guests } = params;

  useEffect(() => {
    setAllParams(params);
    dispatch(saveParams(params));
    console.log("params", params);
  }, []);

  const stepNames = {
    1: "Dates",
    2: "Categories",
    3: "Rates",
    4: "Summary",
    5: "Details",
  };
  const [step, setStep] = useState(0);
  //------------date selection-------------
  const today = new Date();
  const tomorrow = new Date();

  //commented it for now "tomorrow"
  tomorrow.setDate(today.getDate() + 1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: today,
      endDate: tomorrow,
      key: "selection",
    },
  ]);
  const [editableRateItem, setEditableRateItem] = useState("");
  const [promocode, setPromocode] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [proprtyInfo, setPropertyInfo] = useState(null);
  const [propertyAvailability, setPropertyAvailability] = useState(null);
  const [checkinSlot, setCheckinSlot] = useState("");
  const [checkoutSlot, setCheckoutSlot] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRatePlan, setSelectedRatePlan] = useState("");
  const [adultCountFromParams, setAdultCountFromParams] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");
  // const [restaurantList, setRestaurantList] = useState([]);
  const [addonsList, setAddOnsList] = useState([]);
  const [isEditCardItem, setIsEditCardItem] = useState(false);
  const [nextLoader, setNextLoader] = useState(false);
  const [bookingDaysCount, setBookingDaysCount] = useState(0);
  const [roomStays, setRoomStays] = useState([]);
  const [payAtHotel, setPayAtHotel] = useState(false);
  const [cartItemUid, setCartItemUid] = useState("");

  // customer Detail states
  const [tcChecked, setTcChecked] = useState(true);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");

  ///Calculated  amount states
  const [grandTotalRoomCharges, setgrandTotalRoomCharges] = useState(0);
  const [
    grandTotalRoomChargesAfterDiscount,
    setgrandTotalRoomChargesAfterDiscount,
  ] = useState(0);
  const [grandTotalTaxes, setGrandTotalTaxes] = useState(0);
  const [grandTotalTaxesAfterDiscount, setGrandTotalTaxesAfterDiscount] =
    useState(0);
  const [bookingFee, setBookingFee] = useState(0);
  const [temp_curr, setTemp_curr] = useState("");
  const [currencyConversionFactor, setCurrencyConversionFactor] = useState(1);
  const [userPromoDiscount, setUserPromoDiscount] = useState(0);
  const [promoInfo, setPromoInfo] = useState({});
  const [invalidPromo, setInvalidPromo] = useState(false);
  const [finalPayloadAmount, setFinalPayloadAmount] = useState(0);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [
    nonPromoGrandTotalTaxesAfterDiscount,
    setNonPromoGrandTotalTaxesAfterDiscount,
  ] = useState(0);
  const [
    nonPromograndTotalRoomChargesAfterDiscount,
    setNonPromograndTotalRoomChargesAfterDiscount,
  ] = useState(0);
  const [partialPaymentStatus, setPartialPaymentStatus] = useState("DISABLED");
  const [partialPaymentPerc, setPartialPaymentPerc] = useState(0);
  const [partialPayOption, setPartialpayOption] = useState(
    window.location.hostname.includes("getdirectroom")
      ? "pay_partial"
      : "pay_full"
  );
  const [promoApplyLoader, setPromoApplyLoader] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showRateComparison, setShowRateComparison] = useState("DISABLED");

  const [easebuzzPayment, setEasebuzzPayment] = useState(false);
  const [mpesaPayment, setMpesaPayment] = useState(false);
  const [isDefaultHotelPg, setIsDefaultHotelPg] = useState(false);
  const [customPgInfo, setCustomPgInfo] = useState({});
  //----------------Stripe-----------------------------------
  const [stripeSubmitButton, setStripeSubmitButton] = useState(false);
  const [pgAccountType, setPgAccountType] = useState(
    STRIPE_SEPARATE_CHARGE_TYPE
  );
  const [pgAccountId, setPgAccountId] = useState("");
  const [proceesingMessage, setProceesingMessage] = useState("");
  const [roomTypeImages, setRoomTypeImages] = useState([]);
  const url = new URL(window.location.href);
  const source = url.searchParams.get("source") || "BE";
  let bookingSource = source;
  const ctaName = "";

  useEffect(() => {
    localStorage.setItem("ctaId", "");
    localStorage.setItem("ctaName", "");
    if (params.source) {
      if (params.source === "OTA") {
        localStorage.setItem("ctaId", params.ctaId);
        localStorage.setItem("ctaName", params.ctaName);
        urlSearchParams.has("ctaId") && urlSearchParams.delete("ctaId");
        urlSearchParams.has("ctaName") && urlSearchParams.delete("ctaName");
      }
    }
  }, []);

  useEffect(() => {
    setFinalPayloadAmount(
      grandTotalRoomChargesAfterDiscount +
        grandTotalTaxesAfterDiscount +
        bookingFee
    );
  }, [
    grandTotalRoomChargesAfterDiscount,
    grandTotalTaxesAfterDiscount,
    bookingFee,
  ]);
  // var hotelId = params.get("hotel_id");

  const gettingPropertyData = () => {
    setNextLoader(true);
    // setOpenBackdrop(true);
    axios
      .get(`${baseUrl}/core/api/v1/be/get-hotel-content?hotelId=${hotelId}`)
      .then((data) => {
        if (Object.keys(data?.data).length > 0) {
          setPropertyInfo(data?.data);
          dispatch(savePropertyInfo(data?.data));
          dispatch(saveCurrency(data?.data?.hotelCurrency));
          setTemp_curr(data?.data?.hotelCurrency);
          setCurrencySymbol(getCurrencySymbol(data?.data?.hotelCurrency));

          if (
            data?.data?.bePartialPayment &&
            data?.data?.bePartialPayment === "ENABLED"
          ) {
            setPartialPaymentStatus("ENABLED");
            if (data?.bePartialPaymentPerc > 0) {
              setPartialPaymentPerc(data?.bePartialPaymentPerc);
            }
          }
          if (
            data?.data?.showRateComparison &&
            data?.data?.showRateComparison === "ENABLED"
          ) {
            setShowRateComparison("ENABLED");
          }

          if (
            data?.data?.pgAccountType &&
            data?.data?.pgAccountType === STRIPE_DIRECT_CHARGE_TYPE
          ) {
            setPgAccountType(STRIPE_DIRECT_CHARGE_TYPE);
            if (data?.data?.pgAccountId && data?.data?.pgAccountId !== "") {
              setPgAccountId(data?.data?.pgAccountId);
            } else {
              setPgAccountType(STRIPE_SEPARATE_CHARGE_TYPE);
            }
          }
        }
        setNextLoader(false);
        // setOpenBackdrop(false);
      })
      .catch(() => {
        setNextLoader(false);
        // setOpenBackdrop(false);
      });
  };
  const gettingPropertyAvailability = (
    startDate,
    endDate,
    isHandleRoomRateOnNumberOfGuestRequired
  ) => {
    setNextLoader(true);
    // setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/core/api/v1/be/get-hotel-details?hotelId=${hotelId}&checkin=${moment(
          startDate
        ).format("DD-MM-YYYY")} ${checkinSlot}&checkout=${moment(
          endDate
        ).format("DD-MM-YYYY")} ${checkoutSlot}${
          source !== undefined
            ? `&source=${source === "walkin" ? "KIOSK" : source}`
            : ""
        }`
      )
      .then((data) => {
        setPropertyAvailability(data?.data);
        setNextLoader(false);
        // setOpenBackdrop(false);
        setStep(2);
        const numberOfBookingDays = data?.data?.hoursDifference
          ? Math.ceil(data?.data?.hoursDifference / 24)
          : "";
        setBookingDaysCount(numberOfBookingDays);
        if (num_guests && isHandleRoomRateOnNumberOfGuestRequired) {
          handleRoomRateOnNumberOfGuest(data?.data?.roomTypeMap, num_guests);
        }
      })
      .catch(() => {
        setNextLoader(false);
        // setOpenBackdrop(false);
      })
      .catch(() => {});
  };

  const gettingCheckinSlot = (date) => {
    axios
      .get(
        `${baseUrl}/core/api/v1/be/get-checkin-times?hotelId=${hotelId}&date=${date}`
      )
      .then((data) => {
        const checkinSlot = data?.data;
        if (checkinSlot?.times?.length === 1) {
          const date = checkinSlot?.times[0]?.split(" ");
          if (date?.[1] === "AM") {
            setCheckinSlot(`${date?.[0]}:00:00`);
          } else {
            if (date?.[0] === "12") {
              setCheckinSlot(`${date?.[0]}:00:00`);
            } else {
              setCheckinSlot(`${+date?.[0] + 12}:00:00`);
            }
          }
        } else if (checkinSlot?.times?.length === 3) {
          const date = checkinSlot?.times[1]?.split(" ");
          if (date?.[1] === "AM") {
            setCheckinSlot(`${date?.[0]}:00:00`);
          } else {
            if (date?.[0] === "12") {
              setCheckinSlot(`${date?.[0]}:00:00`);
            } else {
              setCheckinSlot(`${+date?.[0] + 12}:00:00`);
            }
          }
        } else {
          setCheckinSlot("12:00:00");
        }
      })
      .catch(() => {});
  };
  const gettingCheckoutSlot = (date) => {
    axios
      .get(
        `${baseUrl}/core/api/v1/be/get-checkout-times?hotelId=${hotelId}&date=${date}`
      )
      .then((data) => {
        const checkoutSlot = data?.data;
        // debugger;
        if (checkoutSlot?.times?.length === 1) {
          const date = checkoutSlot?.times[0]?.split(" ");
          if (date?.[1] === "AM") {
            setCheckoutSlot(`${date?.[0]}:00:00`);
          } else {
            if (date?.[0] === "12") {
              setCheckoutSlot(`${date?.[0]}:00:00`);
            } else {
              setCheckoutSlot(`${+date?.[0] + 12}:00:00`);
            }
          }
        } else if (checkoutSlot?.times?.length === 3) {
          const date = checkoutSlot?.times[1]?.split(" ");
          if (date?.[1] === "AM") {
            setCheckoutSlot(`${date?.[0]}:00:00`);
          } else {
            if (date?.[0] === "12") {
              setCheckoutSlot(`${date?.[0]}:00:00`);
            } else {
              setCheckoutSlot(`${+date?.[0] + 12}:00:00`);
            }
          }
        } else {
          setCheckoutSlot("11:00:00");
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    gettingCheckinSlot(moment(new Date()).format("DD-MM-YYYY"));
    gettingCheckoutSlot(moment(new Date()).format("DD-MM-YYYY"));
    console.log("dateeeee");
  }, []);

  const handleRoomRateOnNumberOfGuest = (pricemap, num_guests) => {
    console.log("pricemap", pricemap);
    let roomTypeId = 0;
    let ratePlanId = 0;
    let minPrice = 99999999999999;
    Object?.keys(pricemap)?.map((roomId) => {
      // debugger;
      if (pricemap?.[roomId]?.combos?.[0]?.availableRooms > 0) {
        pricemap?.[roomId]?.combos?.[0]?.rates?.map((rate) => {
          if (
            rate?.priceMap?.[num_guests] &&
            rate?.discountMap?.[num_guests] &&
            rate?.priceMap?.[num_guests] - rate?.discountMap?.[num_guests] <
              minPrice
          ) {
            minPrice =
              rate?.priceMap?.[num_guests] - rate?.discountMap?.[num_guests];
            ratePlanId = rate?.ratePlanId;
            roomTypeId = roomId;
          } else if (
            rate?.priceMap?.[num_guests] &&
            rate?.priceMap?.[num_guests] < minPrice
          ) {
            minPrice = rate?.priceMap?.[num_guests];
            ratePlanId = rate?.ratePlanId;
            roomTypeId = roomId;
          }
        });
      }
    });
    // console.log("dattttttt", roomTypeId, ratePlanId, minPrice);
    if (roomTypeId > 0) {
      setSelectedRoom(roomTypeId);
      setSelectedRatePlan(ratePlanId);
      setAdultCountFromParams(num_guests);
      setStep(3);
    } else {
      setStep(2);
    }
  };
  // const getRestaurantList = () => {
  //   axios
  //     .get(`${baseUrl}/core/api/v1/be/get-be-shops?hotelId=${hotel_id}`)
  //     .then((data) => {
  //       setRestaurantList(data?.data);
  //       data?.data?.map((item) => {
  //         getAdonsOfEachRestaurant(item?.id);
  //       });
  //     });
  // };
  // const getAdonsOfEachRestaurant = (toLoadShopId) => {
  //   axios
  //     .get(
  //       `${baseUrl}/core/api/v1/be/get-be-shop-detail/?hotelId=${hotel_id}&shopId=${toLoadShopId}`
  //     )
  //     .then((data) => {
  //       const newAddons = [...addonsList, ...data?.data?.products];
  //       setAddOnsList(newAddons);
  //     });
  // };

  const handleCurrencyAmount = (selectedCurr) => {
    if (temp_curr && selectedCurr) {
      setOpenBackdrop(true);
      axios
        .get(
          `${baseUrl}/web-api/currency-convert-value?from=${temp_curr}&to=${selectedCurr}`
        )
        .then((data) => {
          setCurrencyConversionFactor(data?.data);
          setOpenBackdrop(false);
          setCurrencySymbol(getCurrencySymbol(selectedCurr));
        })
        .catch(() => setOpenBackdrop(false));
    }
  };
  useEffect(() => {
    handleCurrencyAmount(Currency);
  }, [Currency]);

  useEffect(() => {
    gettingPropertyData();
  }, []);

  const handleRoomStaysPromoCodeValidation = () => {
    if (cartItemsFromRedux?.length > 0 && promocode) {
      const roomsRequested = [];
      setPromoApplyLoader(true);

      cartItemsFromRedux?.map((item) => {
        //handeling roomStaysArray for payload.
        Array.from(
          { length: item?.propertyRooms },
          (_, index) => index + 1
        ).map((elm) => {
          const roomStaysObject = {
            adultChildList: [
              {
                numAdults: item?.roomWiseAdults[`Room${elm}`],
                numChildren: item?.roomWiseChildren[`Room${elm}`],
              },
            ],
            roomTypeId: item?.selectedRoom,
            ratePlanId: item?.selectedRatePlan,
            dayLevelPriceList: propertyAvailability?.roomTypeMap[
              item?.selectedRoom
            ]?.combos?.[0]?.rates?.filter(
              (rate) => rate?.ratePlanId === item?.selectedRatePlan
            )?.[0]?.dayLevelPriceList,

            discountMap: propertyAvailability?.roomTypeMap[
              item?.selectedRoom
            ]?.combos?.[0]?.rates?.filter(
              (rate) => rate?.ratePlanId === item?.selectedRatePlan
            )?.[0]?.discountMap,
          };

          roomsRequested?.push(roomStaysObject);
        });
      });
      const payload = {
        checkin: propertyAvailability?.checkIn,
        checkout: propertyAvailability?.checkOut,
        hotelId: hotelId,
        numRooms: roomsRequested?.length,
        promoCode: promocode,
        roomsRequested: roomsRequested,
      };
      // console.log("payloadpayloadpayload", payload);
      axios
        .post(`${baseUrl}/core/api/v1/be/promo-code-discount-rates`, payload)
        .then((data) => {
          // console.log("finalPromoCodeDiscount", data?.data);
          if (data?.data?.validPromo) {
            setgrandTotalRoomChargesAfterDiscount(
              data?.data?.amountAfterDiscount
            );
            setGrandTotalTaxesAfterDiscount(data?.data?.taxAfterDiscount);
            setUserPromoDiscount(data?.data?.discountValue);
            setPromoInfo({
              promoCode: promocode,
              discountPercent: data?.data?.discountPercent,
            });
            setInvalidPromo(false);
          } else {
            setgrandTotalRoomChargesAfterDiscount(
              nonPromograndTotalRoomChargesAfterDiscount
            );
            setGrandTotalTaxesAfterDiscount(
              nonPromoGrandTotalTaxesAfterDiscount
            );
            setUserPromoDiscount(0);
            setPromoInfo({});
            setInvalidPromo(true);
          }
          setPromoApplyLoader(false);
        })
        .catch(() => {
          setPromoApplyLoader(false);
        });
    }
  };

  //---------------------ApiPayload-----------------------------------------------------------------------
  const apiPayload = {
    checkin: propertyAvailability?.checkIn,
    checkout: propertyAvailability?.checkOut,
    hotelId: hotelId,
    bookingStatus: "CONFIRMED",
    bookingSource: ["bookflexi", "google"].includes(bookingSource)
      ? "STAYFLEXI_GHA"
      : bookingSource === "stayflexi"
      ? "STAYFLEXI_OM"
      : bookingSource === "walkin"
      ? "STAYFLEXI_HD"
      : bookingSource === "OTA"
      ? ctaName !== undefined
        ? ctaName
        : "STAYFLEXI_OTA"
      : "STAYFLEXI_OD",
    roomStays: roomStays,
    ctaId: "",
    customerDetails: {
      firstName: customerFirstName,
      lastName: customerLastName,
      emailId: customerEmail,
      phoneNumber: customerPhone?.replace(/[^\d]/g, ""),
      country: "",
      city: "",
      zipcode: "",
      address: "",
      state: "",
    },
    paymentDetails: {
      sellRate:
        nonPromograndTotalRoomChargesAfterDiscount +
        nonPromoGrandTotalTaxesAfterDiscount,
      roomRate:
        nonPromograndTotalRoomChargesAfterDiscount +
        nonPromoGrandTotalTaxesAfterDiscount,
      payAtHotel: payAtHotel,
    },
    promoInfo: promoInfo,
    specialRequests: specialRequest,
    isEnquiry: !payAtHotel,
    isAddOnPresent: false,
    posOrderList: [],
    isInsured: false,
    refundableBookingFee: 0,
    bookingFees: bookingFee,

    appliedPromocode: promocode,
    promoAmount: userPromoDiscount,
    requestToBook: false,
  };

  const handlePerformBookingOnly = (encryptedCardData) => {
    apiPayload.encryptedCardData = encryptedCardData;
    handleAmplitude(apiPayload, "GUEST BOOKING WITHOUT PAYMENT", hotelId);
    axios
      .post(`${baseUrl}/core/api/v1/be/perform-booking`, apiPayload)
      .then((data) => {
        // console.log(data);
        if (data?.data?.message?.toUpperCase() === "SUCCESS") {
          const booking_id = data?.data?.bookingId;
          navigate(`/booking-confirmation/${booking_id}`);
        }
        // else {
        //   navigate(`/booking-failure/${hotelId}/${data?.data?.message}`);
        // }
        setBookingLoading(false);
      })
      .catch((err) => {
        navigate(`/booking-failure/${hotelId}/${err?.response?.data?.message}`);
        setBookingLoading(false);
      });
  };

  const handlePerformBookingOrPayment = (encryptedCardData) => {
    setBookingLoading(true);
    if (!payAtHotel) {
      if (finalPayloadAmount !== 0) {
        if (proprtyInfo?.useCustomPGInfo === "ENABLED") {
          handlePerformBookingOnly(encryptedCardData);
        } else {
          handlePayment();
        }
      } else {
        handlePerformBookingOnly();
      }
    } else {
      handlePerformBookingOnly();
    }
  };
  ///-----------------------------Rozerpay-------------------------------------

  const [razorPayFinalKey, setRazorPayFinalKey] = useState(razorPayKey);

  const [beEnquiryStatus, setBeEnquiryStatus] = useState(false);

  useEffect(() => {
    if (hotelId !== null && `${hotelId}`?.length === 5 && proprtyInfo) {
      axios
        .get(
          `${baseUrlDjango}/api/v2/payments/getPGInfoBE/?hotel_id=${hotelId}`
        )
        .then((response) => {
          //Setting the razorpay key as stayflexi key in case the source is google || bookflexi
          if (
            bookingSource !== undefined &&
            (bookingSource === "google" || bookingSource === "bookflexi")
          ) {
            setRazorPayFinalKey(razorPayKey);
          } else {
            setRazorPayFinalKey(
              response?.data?.key === null ? razorPayKey : response?.data?.key
            );
          }
        })
        .catch((error) => {});
    }
  }, [proprtyInfo]);

  //razorpay payment
  const razorPayOptions = {
    key: razorPayFinalKey,
    callback_url: `${baseUrlDjango}/api/v2/payments/razorpaycallback/?hotel_id=${hotelId}`,
    redirect: true,
    currency: "INR",
    amount: finalPayloadAmount * 100,
    name: proprtyInfo?.propertyName,
    description: proprtyInfo?.propertyName,
    image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
    prefill: {
      name: customerFirstName + " " + customerLastName,
      contact: customerPhone?.replace(/[^\d]/g, ""),
      email: customerEmail,
    },
    notes: {
      name: customerFirstName + " " + customerLastName,
      contact: customerPhone?.replace(/[^\d]/g, ""),
      email: customerEmail,
      hotel_id: hotelId,
      hotel_name: proprtyInfo?.propertyName,
      source: "BE_PAYMENT",
      booking_source: bookingSource,
    },
    theme: {
      color: "#000000",
      hide_topbar: false,
    },
    //https://razorpay.com/docs/payments/payment-gateway/web-integration/standard/configure-payment-methods/sample-code/
    config: {
      display: {
        blocks: {
          methods: {
            instruments: [
              {
                method: "card",
              },
              {
                method: "upi",
              },
              {
                method: "netbanking",
              },
              {
                method: "wallet",
              },
            ],
          },
        },
        sequence: ["block.methods"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };

  //Function call to perform razorpay checkout
  const handleRazorpayGateway = (order_id, bookingId) => {
    localStorage.setItem("paymentGateway", "RAZORPAY");
    let name = customerFirstName + " " + customerLastName;
    let phone_val = customerPhone?.replace(/[^\d]/g, "");
    phone_val = phone_val.substring(2);

    // let bookingPayData = {
    //   booking_amount: "Rs. " + finalPayloadAmount,
    //   booking_id: bookingId,
    //   booking_source: bookingSource,
    //   guest_email: customerEmail,
    //   guest_name: name,
    //   guest_phone: customerPhone?.replace(/[^\d]/g, ""),
    //   payment_gateway: "RAZORPAY",
    // };

    // dispatch({
    //   type: "SET_PAYMENT_FAIL_OBJ",
    //   paymentObj: bookingPayData,
    // });

    let cancel_url = `${baseUrlDjango}/api/v2/payments/razorpaycancelurl/?hotel_id=${hotelId}&booking_id=${bookingId}`;
    const current_url = encodeURIComponent(window.location.href);
    cancel_url = cancel_url + `&redirect_url=${current_url}`;
    // if (bookingSource === "google") {

    // }
    let image_url =
      "https://d2pp7czhs34xk7.cloudfront.net/static/favicons/apple-touch-icon.png";
    if (proprtyInfo?.propertyLogo !== "") {
      image_url = proprtyInfo?.propertyLogo;
    }
    let finalUpdatedAmount = finalPayloadAmount * 100;
    if (
      partialPaymentStatus === "ENABLED" &&
      partialPayOption === "pay_partial" &&
      partialPaymentPerc > 0
    ) {
      finalUpdatedAmount = Number(
        Number(finalPayloadAmount * partialPaymentPerc).toFixed(2)
      );
    }
    const params_razorpay = {
      key_id: razorPayFinalKey,
      amount: finalUpdatedAmount,
      order_id: order_id,
      currency: "INR",
      name: proprtyInfo?.propertyName,
      description: proprtyInfo?.propertyName,
      image: image_url,
      "prefill[name]": name,
      "prefill[contact]": customerPhone?.replace(/[^\d]/g, ""),
      "prefill[email]": customerEmail,
      "notes[booking_id]": bookingId,
      "notes[name]": name,
      "notes[contact]": customerPhone?.replace(/[^\d]/g, ""),
      "notes[email]": customerEmail,
      "notes[hotel_id]": hotelId,
      "notes[hotel_name]": proprtyInfo?.propertyName,
      "notes[source]": "BE_PAYMENT",
      "notes[booking_source]": bookingSource,
      "notes[checkin]": propertyAvailability?.checkIn,
      "notes[checkout]": propertyAvailability?.checkOut,
      "notes[roomtype_name]": roomStays[0]?.roomTypeName,
      "notes[rateplan_id]": roomStays[0]?.ratePlanId,
      callback_url: razorPayOptions["callback_url"],
      cancel_url: cancel_url,
    };
    // console.log("razorPayOptions....", params_razorpay, razorPayOptions);
    // return;
    let form = document.createElement("form");
    form.target = "_self";
    form.method = "POST";
    form.action = "https://api.razorpay.com/v1/checkout/embedded";
    for (let i in params_razorpay) {
      if (params_razorpay.hasOwnProperty(i)) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = i;
        input.value = params_razorpay[i];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };

  //MPESA Payment
  const redirectToMPesaPayment = async (bookingId) => {
    let moment_check_in = moment(propertyAvailability?.checkIn, "DD-MM-YYYY");
    let moment_check_out = moment(propertyAvailability?.checkOut, "DD-MM-YYYY");
    let days_diff = moment_check_out.diff(moment_check_in, "days");
    let phone_val = customerPhone?.replace(/[^\d]/g, "");
    phone_val = phone_val.substring(3);
    let booking_amount = Number(finalPayloadAmount).toFixed(2); //amount in 2 digit roundoff in normal currency
    let customer_email = customerEmail;
    let item_code = "null";
    let transaction_code = bookingId;
    let callback_url =
      "https://pay.veeqhatechnologies.com/payment/mpesa/webhook/";
    let redirect_url =
      "https://pay.veeqhatechnologies.com/payment/landing_page?phone=" +
      encodeURIComponent(phone_val) +
      "&amount=" +
      encodeURIComponent(booking_amount) +
      "&stay_flexi_customer_id=" +
      encodeURIComponent(customer_email) +
      "&item_code=" +
      encodeURIComponent(item_code) +
      "&days=" +
      encodeURIComponent(days_diff) +
      "&transaction_code=" +
      encodeURIComponent(transaction_code) +
      "&callback_url=" +
      encodeURIComponent(callback_url);
    console.log("redirecting to payment site...");
    window.location.href = redirect_url;
  };

  //malaysian payment
  function invokeXytronPay(bookingPayloadId) {
    localStorage.setItem("XYTRON");
    let merchant_id = customPgInfo.xytronpay_merchant_id;
    let secret_key = customPgInfo.xytronpay_secret_key;
    let form = document.createElement("form");
    form.target = "_self";
    form.method = "POST";
    let amount = finalPayloadAmount;
    let current_date_time = new Date().toISOString();
    let params = {
      merchant_id: merchant_id,
      secret_key: secret_key,
      transaction_ref_no: bookingPayloadId,
      transaction_date: current_date_time,
      customer_name: customerFirstName + " " + customerLastName,
      customer_email: customerEmail,
      customer_phone: customerPhone,
      currency_code: "MYR",
      amount: amount,
    };
    if (ENV === "BETA") {
      form.action = "https://payment.xytron.com.my/uat/api/v2/create";
    } else {
      form.action = "https://payment.xytron.com.my/api/v2/create";
    }

    params[
      "response_url"
    ] = `${baseUrlDjango}/api/v2/payments/xytronpaycallback/`;

    let hash_string = `&merchant_id=${merchant_id}&transaction_ref_no=${bookingPayloadId}&transaction_date=${current_date_time}&customer_name=${
      customerFirstName + " " + customerLastName
    }&customer_email=${customerEmail}&customer_phone=${customerPhone}&currency_code=MYR&amount=${amount}&secret_key=${secret_key}`;

    params["hash"] = MD5(hash_string).toString();
    params["merchant_ref_1"] = hotelId;
    for (let i in params) {
      if (params.hasOwnProperty(i)) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }
  //--------------Stripe Payment-------------------

  const handleStripeMessage = (toggle) => {
    setBookingLoading(toggle);
  };

  const handleStripeButtonToggle = () => {
    setStripeSubmitButton(false);
  };

  const handleSuccessStripePayment = (token, booking_id) => {
    setBookingLoading(true);
    // setDisablePayButton(true);
    onToken(token, booking_id);
  };

  const onToken = (token, booking_id) => {
    let finalUpdatedAmount = finalPayloadAmount;
    if (
      partialPaymentStatus === "ENABLED" &&
      partialPayOption == "pay_partial" &&
      partialPaymentPerc > 0
    ) {
      finalUpdatedAmount = Number(
        Number(finalPayloadAmount * (partialPaymentPerc / 100)).toFixed(2)
      );
    }
    setBookingLoading(true);
    setProceesingMessage("Payment Done");
    setProceesingMessage("Recording Payment");
    let request_payload = {
      hotelId: hotelId,
      booking_id: booking_id,
      payment_gateway_id: token,
      pg_name: "STRIPE",
      module_source: "BE_PAYMENT",
      booking_source: bookingSource,
      amount: finalUpdatedAmount,
      requires_post_payment_confirmation: "true",
      customer_email: customerEmail,
    };
    if (pgAccountType === STRIPE_DIRECT_CHARGE_TYPE && pgAccountId !== "") {
      request_payload = { ...request_payload, stripe_account: pgAccountId };
    }
    fetch(`${baseUrlDjango}/api/v2/payments/recordPayment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_payload),
    })
      .then(async (res) => {
        if (!res.ok) {
          setProceesingMessage("Recording Payment Failed");
          setTimeout(() => {
            setProceesingMessage("Cancelling Booking...");
          }, 1000);
          let bookingPayData = {
            booking_amount: currencySymbol + " " + finalPayloadAmount,
            booking_id: booking_id,
            booking_source: bookingSource,
            guest_email: customerEmail,
            guest_name:
              apiPayload["customerDetails"]?.firstName +
              " " +
              apiPayload["customerDetails"]?.lastName,
            guest_phone: apiPayload["customerDetails"]?.phoneNumber,
            payment_gateway: "Stripe",
          };

          sendSlackNotification(bookingPayData);

          fetch(`${baseUrl}/core/api/v1/be/cancel-booking`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bookingId: booking_id,
            }),
          })
            .then((res) => {
              if (res.status === 200) {
                setProceesingMessage("Booking Cancelled");
              }
              return res.json();
            })
            .then((data) => {})
            .catch((error) => {});
          return res.json().then((json) => {
            throw json;
          });
        } else {
          setBookingLoading(false);
          if (bookingSource === "walkin") {
            //Redirect to self service
            window.location.replace(
              `${selfServiceUrl}/booking-confirmation/${booking_id}`
            );
            return;
          }
          navigate(`/booking-confirmation/${booking_id}`);
          //to perform pos orders after stripe payment
          setStep(5);
        }
        return res.json();
      })
      .then((data1) => {
        setProceesingMessage("Recording Payment Done");
      })
      .catch((error) => {
        setBookingLoading(false);
      });
  };

  //-------------------------------------------------------
  const handlePayment = async () => {
    if (proprtyInfo?.country === "India") {
      handleAmplitude(apiPayload, "GUEST ON RAZORPAY PAYMENT GATEWAY", hotelId);
      let res = "";

      // console.log("apiPayload", apiPayload);
      // return;

      var currentUrl = new URL(window.location.href);
      let redirectUrl = `${currentUrl?.origin}/booking-confirmation`;
      let failure_prefix = currentUrl?.origin;

      // debugger;
      res = await fetch(`${baseUrl}/core/api/v1/be/perform-booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiPayload),
      });
      if (!res.ok) {
        return res.json().then((json) => {
          throw json;
        });
      }
      const bookingResponse = await res.json();
      if (String(bookingResponse?.status) == "false") {
        navigate("/");
        setBookingLoading(false);
        handleAmplitude(
          apiPayload,
          "PERFORM BOOKING FAILED BEFORE RAZORPAY",
          hotelId
        );
        return;
      }

      if (bookingResponse.message?.toUpperCase() === "SUCCESS") {
        const booking_id = bookingResponse.bookingId;
        let newRedirectUrl = `${redirectUrl}/${booking_id}`;

        let finalUpdatedAmount = finalPayloadAmount;
        let finalCallBackAmount = finalPayloadAmount * 100;
        if (
          partialPaymentStatus === "ENABLED" &&
          partialPayOption == "pay_partial" &&
          partialPaymentPerc > 0
        ) {
          finalUpdatedAmount = Number(
            Number(finalPayloadAmount * (partialPaymentPerc / 100)).toFixed(2)
          );
          finalCallBackAmount = Number(
            Number(finalPayloadAmount * partialPaymentPerc).toFixed(2)
          );
        }
        // addBookingIdAsProperty(hotelId, booking_id);
        razorPayOptions["notes"].booking_id = booking_id;
        razorPayOptions["callback_url"] +=
          "&booking_id=" +
          booking_id +
          "&booking_source=" +
          bookingSource +
          "&amount=" +
          finalCallBackAmount +
          "&request_to_book=" +
          beEnquiryStatus +
          `&redirect_url=${newRedirectUrl}` +
          `&failure_prefix=${failure_prefix}`;
        razorPayOptions["modal"] = {
          escape: false,
          ondismiss: function () {
            fetch(
              `${baseUrl}/core/api/v1/be/post-be-booking/?bookingId=${booking_id}`,
              {
                method: "GET",
              }
            );
          },
        };
        // console.log("razorPayOptions", razorPayOptions);
        // return;
        fetch(`${baseUrlDjango}/api/v2/payments/createrazorpayorder/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            booking_id: booking_id,
            hotel_id:
              bookingSource === "google" || bookingSource === "bookflexi"
                ? ""
                : hotelId,
            module_source: "BE_PAYMENT",
            booking_source: bookingSource,
            amount: finalUpdatedAmount,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            razorPayOptions["order_id"] = data?.id;
            handleRazorpayGateway(data?.id, booking_id);
            setBookingLoading(false);
          })
          .catch((error) => {
            setBookingLoading(false);
          });
      } else {
        navigate(`/booking-failure/${hotelId}/not_available`);
        setBookingLoading(false);
        handleAmplitude(apiPayload, "RAZORPAY PAYMENT FAILED", hotelId);
      }
    } else if (
      ((proprtyInfo?.country === "Malaysia" && isDefaultHotelPg) ||
        proprtyInfo?.country !== "Malaysia") &&
      !mpesaPayment
    ) {
      setStripeSubmitButton(true);
      handleAmplitude(apiPayload, "GUEST ON STRIPE PAYMENT GATEWAY", hotelId);
    } else {
      handlePerformBookingOnly();
    }
  };

  const calculateEndDate = (checkin, numNights) => {
    const endDate = new Date(checkin);
    endDate.setDate(endDate.getDate() + numNights);
    return endDate;
  };

  useEffect(() => {
    if (checkin && num_nights && num_guests && checkinSlot && checkoutSlot) {
      // debugger;
      let dateComponents = checkin.split("-");
      const checkinDate = new Date(
        parseInt(dateComponents[2]),
        parseInt(dateComponents[1]) - 1,
        parseInt(dateComponents[0])
      );
      const calculatedEndDate = calculateEndDate(checkinDate, +num_nights);
      setDateRange([
        {
          startDate: checkinDate,
          endDate: calculatedEndDate,
          key: "selection",
        },
      ]);

      gettingPropertyAvailability(checkinDate, calculatedEndDate, true);

      // showing by default to all property for testing perpose only
      // if (showRateComparison === "ENABLED") {
      gettingRateComparison(checkinDate, calculatedEndDate, num_guests);
      // }
    } else if (!checkin || !num_nights || !num_guests) {
      setStep(1);
    }
  }, [checkin, num_nights, showRateComparison, checkinSlot, checkoutSlot]);

  const [rateCompareData, setRateCompareData] = useState(null);
  const gettingRateComparison = (startDate, endDate, num_guests) => {
    axios
      .get(
        `${baseUrl}/core/api/v1/be/get-rate-comparison?hotelId=${hotelId}&checkin=${moment(
          startDate
        ).format("DD-MM-YYYY")} ${checkinSlot}&checkout=${moment(
          endDate
        ).format("DD-MM-YYYY")} ${checkoutSlot}&totalAdults=${num_guests}`
      )
      .then((data) => {
        // console.log("ratecomparison", data?.data);
        setRateCompareData(data?.data);
      })
      .catch((error) => setRateCompareData(null));
  };
  useEffect(() => {
    setOpenBackdrop(true);
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 500);
  }, [step]);
  useEffect(() => {
    if (step < 3) {
      // setRateCompareData(null);
      setAdultCountFromParams(0);
    }
  }, [step]);

  //--------------------------------------
  const getHotelCustomPgInfo = () => {
    axios
      .get(
        `${baseUrlDjango}/api/v2/payments/getHotelCustomPgInfo/?hotel_id=${hotelId}`
      )
      .then((data) => {
        setCustomPgInfo(data?.data);
        if (
          data?.data?.active_pg_name == "EASEBUZZ" &&
          params["source"] != "google"
        ) {
          setEasebuzzPayment(true);
        } else if (data?.data?.active_pg_name == "MPESA") {
          setMpesaPayment(true);
        } else if (
          proprtyInfo?.country === "Malaysia" &&
          (data === null || data?.data?.active_pg_name === null)
        ) {
          setIsDefaultHotelPg(true);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (
      hotelId !== null &&
      `${hotelId}`?.length === 5 &&
      proprtyInfo &&
      Object.keys(proprtyInfo)?.length > 0
    ) {
      getHotelCustomPgInfo();
    }
  }, [proprtyInfo]);
  useEffect(() => {
    setProceesingMessage("");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    let properties = {};
    let action = "";
    if (step === 1) {
      action = "GUEST ON DATE SELECTION PAGE";
    } else if (step == 2) {
      action = "GUEST ON CATEGORIES PAGE";
      properties.selectdDateRange = dateRange;
    } else if (step === 3) {
      action = "GUEST ON RATE SELLECTION PAGE";
      properties.selectedRoomId = selectedRoom;
    } else if (step === 4) {
      action = "GUEST ON SUMMERY PAGE";
      properties.selectedRatePlanId = selectedRatePlan;
      properties.cartItems = cartItemsFromRedux;
    } else if (step === 5) {
      action = "GUEST ON DETAILS PAGE";
      properties.selectedRatePlanId = selectedRatePlan;
      properties.promoInfo = promoInfo;
    }
    handleAmplitude(properties, action, hotelId);
  }, [step]);

  //-------------room type images-----
  const getingRoomTypeImages = () => {
    axios
      .get(
        `${baseUrl}/core/api/v1/image/get-all-roomtype-images?hotelId=${hotelId}`
      )
      .then((data) => setRoomTypeImages(data?.data))
      .catch(() => {});
  };
  useEffect(() => {
    if (hotelId) {
      getingRoomTypeImages();
    }
  }, [hotelId]);

  return (
    <Box
      className="booking-page"
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${proprtyInfo?.hotelImages?.[0]}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        "@media (max-width: 960px)": {
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))",
        },
      }}
    >
      <Box className="mobile-header">
        <PaginationComponent
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          selectedRoom={selectedRoom}
          propertyAvailability={propertyAvailability}
        />
      </Box>
      <Box className="desktop-header">
        <PaginationDesktop
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          selectedRoom={selectedRoom}
          propertyAvailability={propertyAvailability}
        />
      </Box>

      <Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backdropFilter: openBackdrop ? "blur(4px)" : "none",
          }}
          // open={openBackdrop}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      {step === 1 && (
        <DateSelectionPage
          step={step}
          setStep={setStep}
          setDateRange={setDateRange}
          dateRange={dateRange}
          promocode={promocode}
          setPromocode={setPromocode}
          adults={adults}
          setAdults={setAdults}
          children={children}
          setChildren={setChildren}
          propertyAvailability={propertyAvailability}
          gettingPropertyAvailability={gettingPropertyAvailability}
          gettingRateComparison={gettingRateComparison}
          nextLoader={nextLoader}
          proprtyInfo={proprtyInfo}
          setPropertyAvailability={setPropertyAvailability}
          setSelectedRoom={setSelectedRoom}
          checkin={checkin}
          num_nights={num_nights}
          setRateCompareData={setRateCompareData}
          setSelectedRatePlan={setSelectedRatePlan}
          setCartItemUid={setCartItemUid}
          setEditableRateItem={setEditableRateItem}
          setIsEditCardItem={setIsEditCardItem}
        />
      )}
      {step === 2 && (
        <ProperyCategory
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          allRoomTypeMap={propertyAvailability?.roomTypeMap}
          propertyAvailability={propertyAvailability}
          setSelectedRoom={setSelectedRoom}
          setSelectedRatePlan={setSelectedRatePlan}
          currencySymbol={currencySymbol}
          adults={adults}
          children={children}
          dateRange={dateRange}
          bookingDaysCount={bookingDaysCount}
          currencyConversionFactor={currencyConversionFactor}
          setCartItemUid={setCartItemUid}
          setEditableRateItem={setEditableRateItem}
          setIsEditCardItem={setIsEditCardItem}
          roomTypeImages={roomTypeImages}
        />
      )}
      {step === 3 && (
        <PropertyRates
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          allRoomTypeMap={propertyAvailability?.roomTypeMap}
          propertyAvailability={propertyAvailability}
          currencySymbol={currencySymbol}
          addonsList={addonsList}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          selectedRatePlan={selectedRatePlan}
          setSelectedRatePlan={setSelectedRatePlan}
          isEditCardItem={isEditCardItem}
          setIsEditCardItem={setIsEditCardItem}
          adults={adults}
          children={children}
          dateRange={dateRange}
          bookingDaysCount={bookingDaysCount}
          editableRateItem={editableRateItem}
          setEditableRateItem={setEditableRateItem}
          currencyConversionFactor={currencyConversionFactor}
          cartItemUid={cartItemUid}
          adultCountFromParams={adultCountFromParams}
          rateCompareData={rateCompareData}
          roomTypeImages={roomTypeImages}
        />
      )}
      {step === 4 && (
        <SummaryPage
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          allRoomTypeMap={propertyAvailability?.roomTypeMap}
          propertyAvailability={propertyAvailability}
          currencySymbol={currencySymbol}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          selectedRatePlan={selectedRatePlan}
          setSelectedRatePlan={setSelectedRatePlan}
          isEditCardItem={isEditCardItem}
          setIsEditCardItem={setIsEditCardItem}
          bookingDaysCount={bookingDaysCount}
          editableRateItem={editableRateItem}
          setEditableRateItem={setEditableRateItem}
          roomStays={roomStays}
          setRoomStays={setRoomStays}
          grandTotalRoomCharges={grandTotalRoomCharges}
          setgrandTotalRoomCharges={setgrandTotalRoomCharges}
          grandTotalRoomChargesAfterDiscount={
            grandTotalRoomChargesAfterDiscount
          }
          setgrandTotalRoomChargesAfterDiscount={
            setgrandTotalRoomChargesAfterDiscount
          }
          bookingFee={bookingFee}
          setBookingFee={setBookingFee}
          payAtHotel={payAtHotel}
          setPayAtHotel={setPayAtHotel}
          handleRoomStaysPromoCodeValidation={
            handleRoomStaysPromoCodeValidation
          }
          setGrandTotalTaxes={setGrandTotalTaxes}
          setGrandTotalTaxesAfterDiscount={setGrandTotalTaxesAfterDiscount}
          currencyConversionFactor={currencyConversionFactor}
          grandTotalTaxesAfterDiscount={grandTotalTaxesAfterDiscount}
          userPromoDiscount={userPromoDiscount}
          promocode={promocode}
          setPromocode={setPromocode}
          setNonPromograndTotalRoomChargesAfterDiscount={
            setNonPromograndTotalRoomChargesAfterDiscount
          }
          setNonPromoGrandTotalTaxesAfterDiscount={
            setNonPromoGrandTotalTaxesAfterDiscount
          }
          setCartItemUid={setCartItemUid}
          promoInfo={promoInfo}
          nonPromograndTotalRoomChargesAfterDiscount={
            nonPromograndTotalRoomChargesAfterDiscount
          }
          nonPromoGrandTotalTaxesAfterDiscount={
            nonPromoGrandTotalTaxesAfterDiscount
          }
          setUserPromoDiscount={setUserPromoDiscount}
          setPromoInfo={setPromoInfo}
          promoApplyLoader={promoApplyLoader}
          invalidPromo={invalidPromo}
          setInvalidPromo={setInvalidPromo}
          source={source}
          roomTypeImages={roomTypeImages}
        />
      )}
      {step === 5 && (
        <DetailsPage
          step={step}
          setStep={setStep}
          stepNames={stepNames}
          handlePerformBookingOrPayment={handlePerformBookingOrPayment}
          tcChecked={tcChecked}
          setTcChecked={setTcChecked}
          customerFirstName={customerFirstName}
          setCustomerFirstName={setCustomerFirstName}
          customerLastName={customerLastName}
          setCustomerLastName={setCustomerLastName}
          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}
          customerEmail={customerEmail}
          setCustomerEmail={setCustomerEmail}
          specialRequest={specialRequest}
          setSpecialRequest={setSpecialRequest}
          payAtHotel={payAtHotel}
          setPayAtHotel={setPayAtHotel}
          bookingLoading={bookingLoading}
          proprtyInfo={proprtyInfo}
          allRoomTypeMap={propertyAvailability?.roomTypeMap}
          currencySymbol={currencySymbol}
          currencyConversionFactor={currencyConversionFactor}
          //------------for stripe checkout -------
          buttonKey={stripeSubmitButton}
          stripeMessage={handleStripeMessage}
          buttonToggle={handleStripeButtonToggle}
          successPayment={handleSuccessStripePayment}
          apiPayload={apiPayload}
          addOns={[]}
          disableFunction={setBookingLoading}
          bookingFee={bookingFee}
          partialPaymentStatus={"DISABLED"}
          partialPaymentPerc={0}
          partialPayOption={partialPayOption}
          pgAccountType={pgAccountType}
          pgAccountId={pgAccountId}
          hotelId={hotelId}
          bookingSource={bookingSource}
          propertyAvailability={propertyAvailability}
          isDefaultHotelPg={isDefaultHotelPg}
          mpesaPayment={mpesaPayment}
          currency={currencySymbol}
          proceesingMessage={proceesingMessage}
          finalPayloadAmount={finalPayloadAmount}
        />
      )}
    </Box>
  );
}
